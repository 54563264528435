/**
 * @file
 * Custom JS behaviour for base theme.
 */

(function ($) {
  Drupal.behaviors.Main = {
    attach: function (context, settings) {
      if ($('.view-gemellaggi.view-display-id-page').length > 0) {
        heightAdjust('.view-gemellaggi.view-display-id-page .views-row');
      }
    }
  };
  function heightAdjust(el) {
    var hgt = 0;
    $(el).each(function () {
      if ($(this).height() > hgt) {
        hgt = $(this).height();
      }
    });
    $(el).height(hgt + 'px');
  }
})(jQuery);
